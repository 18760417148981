import { fillInObject } from 'src/components/AppConfig/util/fillInObject';
import React from 'react';
import { Config } from 'src/components/AppConfig/config.types';

export const USER_PORTAL_CONFIG_URL = `${window.location.origin}/userportal.config.json`;
export const CONFIG_URL = `${window.location.origin}/config.json`;
const CONFIG_OVERRIDE_URL = `${window.location.origin}/config.overrides.json`;
export const CONFIG = 'config';

export interface ProviderProps {
    children: React.JSX.Element;
    useUserPortalConfigJson?: boolean;
    onConfigLoaded: Function;
}

export const fetchJson = async (url: string) => {
    const response = await fetch(url, { headers: { Accept: 'application/json' } });
    return response.ok ? response.json() : null;
};
/**
 * Sanitizes log messages to prevent log injection attacks
 * @param message The message to sanitize
 * @returns The sanitized message
 */
const sanitizeLogMessage = (message: any) => {
    if (typeof message === 'string') {
        return message.replace(/[\r\n]/g, ' ');
    }
    return JSON.stringify(message).replace(/[\r\n]/g, ' ');
};
/**
 * Tries to fetch and apply overrides to configs
 * @param config userportal.config.json
 * @returns configs with overridden values if overrides are found, else the original configs
 */
export const applyOverrides = async (config: any) => {
    const override = await fetchJson(CONFIG_OVERRIDE_URL);
    if (override) {
        // eslint-disable-next-line no-console
        console.log('Using configuration overrides:');
        try {
            // eslint-disable-next-line no-console
            console.log(sanitizeLogMessage(override));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        return fillInObject(config, override);
    }
    return config;
};
/**
 * Takes the old/ deprecated userPortal.config.json and translates it to match the appConfig.
 * This is needed as a compatibility layer for public.
 * @param userportalConfig userportal.config.json
 * @returns config.json
 */
export const userPortalTranslator = (userportalConfig: any): Config => {
    // CENPLAT-10941 delete this function
    const result: Config = {
        ...userportalConfig,
    };
    // Only the segment write key and environment need to be translated other values
    // compatibility hack is only needed for central_segmentWriteKey and that it should never be need for blocksync/ cloud
    result.blocksync_segmentWriteKey = userportalConfig.segment?.writeKey ?? '';
    result.central_segmentWriteKey = userportalConfig.segment?.writeKey ?? '';
    result.environment = userportalConfig.sentryEnvironment ?? 'development';
    return result;
};
/**
 * @returns The config from the session.
 */
export const getConfig = (): Config => JSON.parse(sessionStorage.getItem(CONFIG) ?? '{}');
/**
 * Can be used for error handling purposes for when the config fails to load
 * @returns True if there is config in the session storage
 */
export const isConfigSet = (): boolean => {
    const config = sessionStorage.getItem(CONFIG);
    return !!(config && config !== '{}');
};
